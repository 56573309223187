class ZIDXAccountSiteInvite implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSiteInviteContainer";
    }
    getMatchURL(){
        return "/account/sites/invite";
    }
    render(){
        // console.log("site invite");
    }
}